<template>
    <div class="footer">
        <div class="img-box">
            <img src="../assets/happy/1.png" alt="">
            <img src="../assets/happy/2.png" alt="">
            <img src="../assets/happy/3.png" alt="">
            <img src="../assets/happy/5.png" alt="">
            <img src="../assets/happy/6.png" alt="">
            <img src="../assets/happy/7.png" alt="">
            <img src="../assets/happy/8.png" alt="">
            <img src="../assets/happy/9.png" alt="">
            <img src="../assets/happy/10.png" alt="">
        </div>
        <hr class="hr">
        <div class="bottom-copy">
            網站地圖 ｜ 節制過度投注 ｜ 隱私權保護政策 ｜ 中信銀行 彩票管理部 ｜ 財政部 國庫署 ｜ 常見問答
            <br>
            <span style="color: #00FF7F">未滿十八歲者「不得購買或兌領彩票」</span>
            <br>
			建議瀏覽器：Microsoft Edge、Google Chrome；解析度1024x768 | 版權所有 2014 台灣彩票股份有限公司
        </div>
    </div>
</template>
<script>
export default {
	name: 'FooterView',
	components: {
	},
	data(){
		return {
		}
	},
	mounted(){
		
	}
}
</script>
<style lang="scss" scoped>
.footer{
    margin-top: 60px;
    padding: 20px 30px;
    color: #fff;
    font-weight: bold;
    background-color: #006D33;
    .img-box{
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        width: 1170px;
        margin: 10px auto 0;
        padding: 0 15px;
        box-sizing: border-box;
        img{
            width: 90px;
            height: 45px;
        }
    }
    .hr{
        width: 1124px;
        margin: 8px auto;
        border: 0;
        border-top: 1px solid #eee;

    }
    .bottom-copy{
        text-align: center;
        opacity: .5;
        font-weight: normal;
        font-size: 12.6px;
        line-height: 18px;
    }
}
@media only screen and (min-width: 320px) and (max-width: 479px){
    .footer{
        padding: 20px 15px !important;
    }
    .img-box{
        justify-content: center !important;
        width: 100% !important;
        img{
            width: 70px !important;
            height: 35px !important;
            margin: 0 2px !important;
        }
    }
    .bottom-copy{
        line-height: 18px !important;
    }
}
</style>