<template>
    <div class="main">
        <div class="section">
            <div class="session-title">
                最 新 開 獎 號 碼
                <span>New Lottery Number</span>
            </div>
            <div class="game-info">
                <div class="big-digit">
                    <div class="digit-box" v-for="(item, index) in 5" :key="index">
                        {{ index==0?bsLotteryVo.firstNumber:index==1?bsLotteryVo.secondNumber:index==2?bsLotteryVo.thirdNumber:index==3?bsLotteryVo.fourthNumber:bsLotteryVo.fifthNumber }}
                    </div>
                </div>
                <div class="current-period-info">
                    <span>期號：{{bsLotteryVo.issueNumber}}</span>
                    <span>開獎時間：{{bsLotteryVo.lotteryOpenTime}}</span>
                    <span>開獎倒計時：{{minute}}分{{second}}秒</span>
                </div>
                <div class="btn">
                    <div class="btn-box" @click="refresh">刷新結果</div>
                    <div class="btn-box" @click="history">歷史開獎號碼</div>
                </div>
            </div>
            <div class="history">
                <el-table :data="tableData" border stripe
                    :style="screenWidth > 320 && screenWidth < 479 ? 'width: 365.2px;margin: 0 auto;' : 'width: 100%;border-radius: 10px;'"
                    :header-cell-class-name="headerClass" v-if="screenWidth>=479">
                    <el-table-column prop="issueNumber" label="期號" :width="screenWidth > 320 && screenWidth < 479 ? '60' : '153'">
                    </el-table-column>
                    <el-table-column prop="address" label="號碼"
                        :width="screenWidth > 320 && screenWidth < 479 ? '170' : '307'">
                        <template slot-scope="scope">
                            <div class="number-main">
                                <div class="n-top">
                                    <div class="number-box" v-for="item, index in 5" :key="index">
                                        {{ index==0?scope.row.firstNumber:index==1?scope.row.secondNumber:index==2?scope.row.thirdNumber:index==3?scope.row.fourthNumber:scope.row.fifthNumber }}
                                    </div>
                                </div>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="lotteryOpenTime" label="日期"
                        :width="screenWidth > 320 && screenWidth < 479 ? '60' : '256'">
                    </el-table-column>
                    <el-table-column label="總和">
                        <el-table-column  prop="fiveNumberSum"
                            :width="screenWidth > 320 && screenWidth < 479 ? '30' : '100'">
                        </el-table-column>
                        <el-table-column  prop="singleOrDouble"
                            :width="screenWidth > 320 && screenWidth < 479 ? '23' : '100'">
                        </el-table-column>
                        <el-table-column  prop="largeOrSmall"
                            :width="screenWidth > 320 && screenWidth < 479 ? '22' : '108'">
                        </el-table-column>
                    </el-table-column>
                </el-table>
                <div class="table-data" v-else>
                    <div class="data-ul">
                        <div class="data-li" v-for="(item,index) in tableData" :key="index">
                            <div>{{item.issueNumber}}</div>
                            <div class="data-num">
                                <div class="num" v-for="(items,indexs) in 5" :key="indexs">
                                    {{ indexs==0?item.firstNumber:indexs==1?item.secondNumber:indexs==2?item.thirdNumber:indexs==3?item.fourthNumber:item.fifthNumber }}
                                </div>
                            </div>
                            <div>{{item.lotteryOpenTime}}</div>
                            <div>{{item.fiveNumberSum}} {{ item.singleOrDouble }} {{ item.largeOrSmall }}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="special">
                <div class="special-title">熱門號碼</div>
                <div class="special-box">
                    <div class="special-num" v-for="(item,index) in popularNumbers" :key="index">{{ item }}</div>
                </div>
            </div>
            <div class="special">
                <div class="special-title">冷門號碼</div>
                <div class="special-box">
                    <div class="special-num" v-for="(item,index) in unusualNumbers" :key="index">{{ item }}</div>
                </div>
            </div>
            <div class="session-title">
                機 構 介 紹
                <span>Introduce</span>
            </div>
            <div class="session-text">
                <p>&nbsp;&nbsp;&nbsp;&nbsp;台灣彩票為中國信託金控旗下子公司，是公益彩票發行機構中國信託銀行之受委託機構。中國信託銀行獲得財政部認可，自第3屆(民國96年到102年)賦予發行公益彩票的責任，並獲指定繼續擔任第4屆(民國103年到112年)公益彩票之發行機構。中國信託為落實「公益」使命，如同中國信託慈善基金會辜仲諒董事長所說，要把經營彩票賺的每一塊錢都拿出來做公益，經營彩票不是要賺錢，不論盈虧，每年都會提供27億元回饋金挹注政府社福財源，以照顧更多弱勢族群。台灣彩票為社會大眾提供多樣化的公益彩票商品，透過專業的彩票銷售管道，廣召社會大眾的參與，協助充實政府公益彩票盈餘與推動公益彩票產業健全化。&nbsp;</p>
                <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;台灣政府發行公益彩票有別於其他國家，是以照顧弱勢族群為首要目標，台灣彩票所經營的是為加強政府社會福利資源的公益事業，一直積極推動「買彩票，做公益，積功德」的理念，讓民眾了解買彩票除了有機會中大獎之外，也提供社會弱勢群眾的就業機會。另外，鼓勵中獎人捐出部分獎金做公益，亦是台灣彩票經營公益彩票的一大特色，自民國96年起，累積至民國111年12月止，台灣彩票累積募捐超過新台幣72億元，其中民國100年由中獎人共同捐贈100輛復康巴士給全國各縣市社會局處，至少幫助了200個社會福利機構及公益團體，實際參與超過1408項公益慈善專案，全國已有超過942萬人次的弱勢族群受惠，不僅是中獎人愛心的展現，更彰顯了政府發現公益彩票的公益本質。</p>
                <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;為了擴大彩票公司產品力和娛樂性，而專門設計開發出多元化易玩性高頻型彩種「台灣賓果幸運5」，獨立成為台灣彩票全資子公司，「台灣賓果幸運5」是在公司原有的高頻型彩種「BINGO BINGO賓果賓果」，每五分鐘開獎一次，從號碼選號範圍為01~80，簡化號碼選擇範圍為0~9，只需要在5個位置選擇喜歡的號碼，每次開獎時，電腦系統將隨機開出5個獎號，您可以依您選擇的玩法和選號進行對獎。如您的選號符合該期任一種中獎情形，即為中獎，並可依規定兌領獎金。該彩種從民國109年國慶日零時起，面向社會公開售賣，隨即帶動銷售風潮，造成高頻型彩種熱賣，銷售連年創新高，再創社會公益性彩票熱潮，讓更多的民眾透過購買彩票的實際行動支持公益彩票發行的公益目的。</p>
                <p><p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;日後台灣彩票將繼續宣揚「買彩票，做公益，積功德」理念，善盡企業社會責任與公益使命，持續為推廣公益彩票貢獻心力量。</p></p>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'Happy',
    data() {
        return {
            screenWidth: null,
            tableData: [],//历史数据
            popularNumbers:[],//热门号码
            unusualNumbers:[],//冷门号码
            bsLotteryVo:{},//详情
            minute:0,
            second:0,
            timer:null,
            opentime:null
        }
    },
    mounted() {
        this.screenWidth = document.body.clientWidth
        window.onresize = () => {
            return (() => {
                this.screenWidth = document.body.clientWidth
            })()
        }
        this.refresh()
    },
    methods: {
        headerClass(row) {
            if (row.rowIndex === 1) {
                return 'header-row-display';
            }
        },
        refresh(){
            this.$axios.get(this.$global.host + '/lottery/record/refreshResult').then(res => {
                if(res.data.code==200){
                    this.tableData = res.data.data.bsLotteryVoList
                    this.bsLotteryVo = res.data.data.bsLotteryVo
                    this.popularNumbers = res.data.data.bsLotteryNumberRelatedVo.popularNumbers
                    this.unusualNumbers = res.data.data.bsLotteryNumberRelatedVo.unusualNumbers
                    this.opentime = res.data.data.bsLotteryNumberRelatedVo.lotteryCountDown
                    this.countdowm()
                }
            })
        },
        countdowm() {
            if (!this.timer) {
                this.timer = setInterval(() => {
                    // 台湾快乐5倒计时
                    if (this.opentime !== 0 && this.opentime > 0) {
                        this.opentime--;
                        this.minute = this.countdowmTime(this.opentime).minute
                        this.second = this.countdowmTime(this.opentime).second
                    } else {
                        if (this.opentime !== null) {
                            this.refresh()
                        }
                    }
                }, 1000);
            }
        },
        countdowmTime(time) {
            let day = Math.floor(time / 86400);
            let hour = Math.floor((time / 3600) % 24);
            let min = Math.floor((time / 60) % 60);
            let sec = Math.floor(time % 60);
            return {
                day: day,
                hour: hour < 10 ? "0" + hour : hour,
                minute: min < 10 ? "0" + min : min,
                second: sec < 10 ? "0" + sec : sec
            }
        },
        history(){
            this.$router.push('./happyHistory')
        }
    }
}
</script>
<style scoped lang="scss">
.main {
    width: 100%;
    max-height: 100%;
    background-image: url('../assets/happy/bg.svg');

    .section {
        width: 1170px;
        padding: 0 15px;
        box-sizing: border-box;
        margin: 10px auto 0;

        .session-title {
            background: linear-gradient(90deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%) no-repeat;
            font-size: 16.8px;
            font-variant: small-caps;
            font-weight: 700;
            padding: 5px 5px 5px 10px;
            border-left: 8px solid #006d34;
            border-radius: 5px;
            color: #333;

            span {
                font-variant: small-caps;
                font-size: 12px;
            }
        }

        .game-info {
            text-align: center;
            margin-bottom: 20px;
            margin-top: 20px;

            .big-digit {
                display: flex;
                align-content: center;
                justify-content: center;

                .digit-box {
                    width: 50px;
                    height: 50px;
                    line-height: 50px;
                    font-size: 32px;
                    opacity: 85%;
                    background: #FFC926;
                    border-radius: 100%;
                    display: inline-block;
                    margin: 0 3px;
                    color: #006D33;
                    text-align: center;
                    font-family: Arial;
                    font-weight: 700;
                }
            }

            .current-period-info {
                margin-top: 20px;

                span {
                    font-size: 14px;
                    line-height: 30px;
                    background-color: #006D33;
                    border-radius: 5px;
                    color: #ffffff;
                    padding: 6px 10px;
                    margin: 5px;
                }
            }

            .btn {
                margin: 25px 0 10px;
                display: flex;
                align-items: center;
                justify-content: center;

                .btn-box {
                    width: 120px;
                    height: 35px;
                    border-radius: 8px;
                    font-size: 14px;
                    border: none;
                    background-color: #ce5854;
                    color: #ffffff;
                    cursor: pointer;
                    text-align: center;
                    line-height: 33px;
                    margin: 0 7px;
                    padding: 1px 6px;
                    box-sizing: border-box;
                }
            }
        }

        .history {
            width: 90%;
            margin: 0 auto;
            background: rgba(255, 255, 255, 0.5);
            box-shadow: 2px 2px 7px rgba(0, 0, 0, 0.1);
            border-radius: 10px;
            box-sizing: border-box;

            ::v-deep table {
                width: 100%;

                .el-table__cell {
                    padding: 5px 0;
                    border-right: none;
                    .cell{
                        color: #333;
                    }
                }

                tr {
                    width: calc(100% - 20px);
                    text-align: center;
                    padding: 0 10px;
                    box-sizing: border-box;

                    th {
                        height: 30px;
                        font-size: 14px;
                        font-weight: 400;
                        text-align: center;
                        background: #006D33;
                        box-sizing: border-box;
                        .cell{
                            color: #fff !important;
                            border: none !important;
                            line-height: 30px !important;
                        }
                    }

                    td {
                        box-sizing: border-box;
                        text-align: center;
                        .cell{
                            line-height: 30px !important;
                        }
                    }

                    .header-row-display {
                        display: none !important;
                    }

                    .number-main {
                        display: block !important;

                        .n-top {
                            display: flex;
                            align-items: center;
                            justify-content: center;

                            .number-box {
                                margin: 0 3px;
                                text-align: center;
                                height: 30px;
                                width: 30px;
                                color: #006D33;
                                border-radius: 50px;
                                line-height: 30px;
                                background-color: #FFC926;
                                font-family: Arial;
                                opacity: 85%;
                                font-weight: 700;
                            }
                        }
                    }
                }

                // tr:nth-child(even) {
                //     background: #e7e7e7;
                // }
            }
            .table-data{
                width: 100%;
                background-color: #fff;
                border-radius: 8px;
                .data-ul{
                    .data-li{
                        font-size: 14px;
                        color: #333;
                        text-align: center;
                        line-height: 30px;
                        border-bottom: 1px solid #ccc;
                        border-left: 1px solid #ccc;
                        border-right: 1px solid #ccc;
                        .data-num{
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            .num{
                                background: #FFC926;
                                border-radius: 100%;
                                width: 30px;
                                height: 30px;
                                display: inline-block;
                                margin: 0 3px;
                                color: #006D33;
                                line-height: 30px;
                                text-align: center;
                                font-family: Arial;
                                opacity: 85%;
                                font-weight: 700;
                            }
                        }
                    }
                }
            }
        }
        .special{
            width: 90%;
            margin: 10px auto;
            background: rgba(255, 255, 255, 0.5);
            box-shadow: 2px 2px 7px rgba(0, 0, 0, 0.1);
            border-radius: 10px;
            overflow: hidden;
            box-sizing: border-box;
            .special-title{
                height: 30px;
                font-size: 16px;
                font-weight: 400;
                line-height: 30px;
                text-align: center;
                background: #006D33;
                box-sizing: border-box;
                color: #fff;
            }
            .special-box {
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 5px 10px;
                .special-num {
                    margin: 0 3px;
                    text-align: center;
                    height: 30px;
                    width: 30px;
                    color: #006D33;
                    border-radius: 50px;
                    line-height: 30px;
                    background-color: #FFC926;
                }
            }
        }
        .session-text{
            margin-top: 20px;
            line-height: 26px;
            p{
                margin-bottom: 10px;
                color: #333;
            }
        }
    }
}
@media only screen and (min-width: 320px) and (max-width: 479px){
    .section{
        width: 100% !important;
        box-sizing: border-box;
        .current-period-info{
            span{
                display: block;
                width: 270px;
                margin: 5px auto !important;
                box-sizing: border-box;
            }
        }
        .history{
            width: 100% !important;
        }
        .special{
            width: 100% !important;
        }
    }
}
</style>