<template>
  <div id="app">
	  <HeaderView v-if="$route.meta.kj"></HeaderView>
    <router-view/>
    <FooterView v-if="$route.meta.kj"></FooterView>
  </div>
</template>

<script>
import HeaderView  from "../src/components/Header.vue";
import FooterView  from "../src/components/footer.vue";
export default {
  name: 'app',
  components: {
    HeaderView,
    FooterView
  },
  mounted(){
    console.log(this.$route)
  }
}
</script>

<style>
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  overflow-x: hidden;
  font-size: 14px;
}
html,body{
  background-color: #f4f4f4;
  line-height: 1.42857143;
}
* {
    margin: 0;
    padding: 0;
    user-select:text;
    font-family: '微软雅黑';
}

</style>
