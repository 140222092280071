import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import global_msg from './util/global.js'   //注意文件路径，实际路径以项目目录结构为准
import { Table,TableColumn,Pagination,Message,Button,Upload,DatePicker,Select,Option } from 'element-ui';
Vue.use(Table).use(TableColumn).use(Pagination).use(Button).use(Upload).use(DatePicker).use(Select).use(Option)
// import audio from 'vue-mobile-audio'
// Vue.use(audio)
Vue.prototype.$global = global_msg;
Vue.prototype.$axios = axios
Vue.prototype.$message = Message

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
